import * as React from "react"
import Layout from "../../Layout/layout"
import "./campaign_list.css"
import CampaignTable from "./campaignTable"
import { useState } from "react"
import NewCampaignPopup from "./newCampaignPopup"
import { Link } from "gatsby"
const List = () => {
  const [campaignSettingBlock, setCampaignSettingBlock] = useState(false),
    [chatBoat, setChatBoat] = useState(""),
    [chatBoatError, setChatBoatError] = useState(""),
    [googleTagId, setGoogleTagId] = useState(""),
    [googleTagIdError, setGoogleTagIdError] = useState(""),
    ShowCampaignSettingBlock = () => {
      setCampaignSettingBlock(true)
    },
    hideCampaignSettingBlock = () => {
      setCampaignSettingBlock(false)
      setChatBoatError("")
      setChatBoat("")
      setGoogleTagId("")
      setGoogleTagIdError("")
    }
  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            {campaignSettingBlock && (
              <div className="row">
                <div className="col-lg-12 mb-4 campaignSettingsContainer">
                  <div className="row">
                    <div className="col-lg-7 col-md-10 mx-auto">
                      <div className="row">
                        <div className="col-lg-12 colHeading">
                          <strong>
                            <i className="bi bi-gear-fill" /> Marketing Settings
                          </strong>
                          <div className="float-end">
                            <button
                              type="button"
                              className="btn-close btnCloseCampaignSettings"
                              onClick={hideCampaignSettingBlock}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 dh-block-body">
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Chatbot</label>
                            <div className="col-sm-8">
                              <input type="text" className="form-control" name="chatbot" />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">
                              Optional: Google Tags ID{" "}
                              <span data-bs-toggle="tooltip" data-bs-title="i.e. GTM-XXXXXXX">
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </label>
                            <div className="col-sm-8">
                              <input type="text" className="form-control" name="google_tag_id" />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-sm-4 col-form-label" />
                            <div className="col-sm-8">
                              <button
                                className="btn btn-default btnCloseCampaignSettings"
                                onClick={hideCampaignSettingBlock}
                              >
                                <i className="bi bi-x-lg" /> Close
                              </button>
                              <button className="btn btn-primary">
                                <i className="bi bi-check2" /> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row tabularMenuContainer">
              <div className="col">
                <div className="input-group filterDropdown mb-1">
                  <input type="search" className="form-control tableSearch" placeholder="Search" />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className="btn btn-default mb-1 btnOpenCampaignSettings"
                        data-bs-toggle="tooltip"
                        title="Marketing Settings"
                        onClick={ShowCampaignSettingBlock}
                      >
                        <i className="bi bi-gear-fill" />
                      </button>
                    </li>
                    <li className="nav-item ms-1" role="presentation">
                      <div className="btn-group mt-0 mb-1 btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" /> <span className="d-none d-md-inline">New</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li>
                            <NewCampaignPopup />
                          </li>
                          <li>
                            <Link
                              to={"/contacts/groups"}
                              className="dropdown-item btnAddNewLease"
                              data-bs-toggle="tooltip"
                            >
                              <i className="bi bi-megaphone-fill" /> Message Broadcast
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/drive?addSequence=addSequence"}
                              className="dropdown-item btnAddNewPaymentOnFile"
                              data-bs-toggle="tooltip"
                              title="Add Message Sequence"
                            >
                              <i className="bi bi-three-dots" /> Message Sequence
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/contacts/groups?addGroup=addGroup"}
                              className="dropdown-item btnAddNewPaymentOnFile"
                              data-bs-toggle="tooltip"
                              title="Add Audience / Group / Tribe"
                            >
                              <i className="bi bi-people-fill" /> Audience / Group / Tribe
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a className="nav-link" href="#" data-bs-toggle="tooltip" data-bs-title="Trash">
                        <i className="bi bi-trash-fill" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <CampaignTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default List
