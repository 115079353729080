export const timeFormatter = timeString => {
  let date = new Date(timeString)

  // return 2022-8-8 13:54:59
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds()
  )
}

export const timeFormatForName = () => {
  let date = new Date()
  let months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  }
  return months[date.getMonth()] + " ," + date.getDate() + " " + date.getFullYear()
}
