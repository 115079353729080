import * as React from "react"

import PrivateRoute from "../../components/privateRoute"
import List from "../../components/Campaign/Listing/list"
import ReduxWrapper from "../../redux/reduxWrapper"

const Campaigns = props => {
  return <PrivateRoute component={List} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<Campaigns {...props} />} />
export default WrappedPage
