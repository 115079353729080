import * as React from "react"
import "./campaign_list.css"
import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import {
  CAMPAIGN_OPT_CHAT_BOT,
  CAMPAIGN_OPT_CLICK,
  CAMPAIGN_OPT_DOC,
  CAMPAIGN_OPT_OPTIONS,
  CAMPAIGN_OPT_PAYMENT,
  CAMPAIGN_OPT_SCHEDULER,
  REQUEST_OPTIONS,
} from "./addCampaignHelper"
import { timeFormatForName } from "../../../services/timeHelper"
import validator from "validator/es"
import AxiosInstance from "../../../services/axiosInstance"

const NewCampaignPopup = () => {
  const [modalShow, setModalShow] = useState(false),
    [groups, setGroups] = useState([]),
    [selectedGroupName, setSelectedGroupName] = useState(""),
    [docs, setDocs] = useState([]),
    [loadGroups, setLoadGroups] = useState(true),
    [loadDocs, setLoadDocs] = useState(true),
    [optDropDown, setOptDropDown] = useState(""),
    [specificDoc, setSpecificDoc] = useState(""),
    [specificDocName, setSpecificDocName] = useState(""),
    [specificProduct, setSpecificProduct] = useState(""),
    [specificProductName, setSpecificProductName] = useState(""),
    [specificScheduler, setSpecificScheduler] = useState(""),
    [specificSchedulerName, setSpecificSchedulerName] = useState(""),
    [specificBot, setSpecificBot] = useState(""),
    [specificBotName, setSpecificBotName] = useState(""),
    [clickThrough, setClickThrough] = useState(""),
    [reqOptType, setReqOptType] = useState(""),
    [campaignName, setCampaignName] = useState(""),
    [removeReceipt, setRemoveReceipt] = useState(true),
    [pauseSequence, setPauseSequence] = useState(true),
    [formError, setFormError] = useState(""),
    [groupError, setGroupError] = useState(""),
    [campaignNameError, setCampaignNameError] = useState(""),
    [optDropDownError, setOptDropDownError] = useState(""),
    [specificDataError, setSpecificDataError] = useState(""),
    [clickUrlError, setClickUrlError] = useState(""),
    [requestViaError, setRequestViaError] = useState(""),
    [requestViaSeqError, setRequestViaSeqError] = useState(""),
    clearErrors = () => {
      setGroupError("")
      setCampaignNameError("")
      setOptDropDownError("")
      setSpecificDataError("")
      setClickUrlError("")
      setRequestViaError("")
      setRequestViaSeqError("")
    },
    prepareCampaignName = (groupName = null, label = null, option = null) => {
      let cgName = groupName ? groupName : selectedGroupName
      let spLabel = label ? label : "{doctitle} {eSign}"
      let optDropDownType = option ? option : optDropDown

      if (!label) {
        if (optDropDownType === CAMPAIGN_OPT_DOC) {
          spLabel = specificDocName
        }
        if (optDropDownType === CAMPAIGN_OPT_PAYMENT) {
          spLabel = specificProductName
        }
        if (optDropDownType === CAMPAIGN_OPT_SCHEDULER) {
          spLabel = specificSchedulerName
        }
        if (optDropDownType === CAMPAIGN_OPT_CHAT_BOT) {
          spLabel = specificBotName
        }
      }
      setCampaignName(spLabel + " requested from " + cgName + " on " + timeFormatForName())
    },
    updateSpecificData = (data, label) => {
      setSpecificDoc("")
      setSpecificDocName("")
      setSpecificProduct("")
      setSpecificProductName("")
      setSpecificScheduler("")
      setSpecificSchedulerName("")
      setSpecificBot("")
      setSpecificBotName("")
      setClickThrough("")
      if (optDropDown === CAMPAIGN_OPT_DOC) {
        setSpecificDocName(label)
        setSpecificDoc(data)
      }
      if (optDropDown === CAMPAIGN_OPT_PAYMENT) {
        setSpecificProductName(label)
        setSpecificProduct(data)
      }
      if (optDropDown === CAMPAIGN_OPT_SCHEDULER) {
        setSpecificSchedulerName(data)
        setSpecificScheduler(data)
      }
      if (optDropDown === CAMPAIGN_OPT_CHAT_BOT) {
        setSpecificBotName(data)
        setSpecificBot(data)
      }
    },
    getGroups = async () => {
      await AxiosInstance.get(`/contact/group/list-all`).then(response => {
        setGroups(response.data.groups)
      })
    },
    getDocs = async () => {
      await AxiosInstance.get(`/drive/doc/list-all`).then(response => {
        setDocs(response.data.docs)
      })
    },
    handleClose = () => {
      setModalShow(false)
      setOptDropDown("")
      setSpecificDoc("")
      setSpecificProduct("")
      setSpecificScheduler("")
      setSpecificBot("")
      setClickThrough(false)
      setReqOptType("")
    },
    submitCampaign = e => {
      e.preventDefault()
      const formData = new FormData(e.target)
      clearErrors()
      let error = false
      let group_list = formData.get("group_list")
      let campaign_opt_2 = formData.get("campaign_opt_2")
      let requestVia = formData.get("campaignSpecificOnceChoosen")
      let requestViaSequence = formData.get("sequence_list_for_start_campaign")
      if (!group_list || group_list === "") {
        setGroupError("Please select Contact Group.")
      }

      if (!campaign_opt_2 || campaign_opt_2 === "") {
        setOptDropDownError("Please Campaign Option.")
      }

      if (optDropDown === CAMPAIGN_OPT_DOC && !specificDoc) {
        setSpecificDataError("Please select Doc.")
        error = true
      }
      if (optDropDown === CAMPAIGN_OPT_PAYMENT && !specificProduct) {
        setSpecificDataError("Please select Product.")
        error = true
      }
      if (optDropDown === CAMPAIGN_OPT_SCHEDULER && !specificScheduler) {
        setSpecificDataError("Please select Sequence.")
        error = true
      }
      if (optDropDown === CAMPAIGN_OPT_CHAT_BOT && !specificBot) {
        setSpecificDataError("Please select chat bot.")
        error = true
      }

      if (optDropDown === CAMPAIGN_OPT_CLICK && !clickThrough && !validator.isURL(clickThrough)) {
        setClickUrlError("Please Add valid click url.")
        error = true
      }
      if (!requestVia) {
        setRequestViaError("Please select request via option.")
        error = true
      }

      if (requestVia === "Sequence" && (!requestViaSequence || requestViaSequence === "")) {
        setRequestViaSeqError(" Please select sequence.")
        error = true
      }
      if (!campaignName) {
        setCampaignNameError("Please add campaign Name.")
        error = true
      }
      if (error === false) {
        saveCampaignData({
          groupId: group_list,
          name: campaignName,
          campaignOpt: campaign_opt_2,

          clickThrough: clickThrough,

          specificDoc: specificDoc,
          specificProduct: specificProduct,
          specificScheduler: specificScheduler,
          specificBot: specificBot,

          requestVia: requestVia,
          requestViaSequence: requestViaSequence,
          removeReceipt: removeReceipt,
          pauseSequence: pauseSequence,
        })
      }
    },
    saveCampaignData = async data => {
      await AxiosInstance.post(`/campaign/add`, data)
        .then(function (response) {
          if (response.status === 201 && response.data.campaign !== undefined) {
            navigate("/campaigns/detail/" + response.data.campaign)
            return false
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setFormError(error.response.data.message)
          } else if (error.response.data) {
            setFormError(error.response.data.detail)
          }
        })
    }

  useEffect(() => {
    if (loadGroups && groups.length === 0) {
      setLoadGroups(false)
      getGroups()
    }

    if (loadDocs && docs.length === 0) {
      setLoadDocs(false)
      getDocs()
    }
  })

  return (
    <React.Fragment>
      <a className="dropdown-item btnAddNewSubscription" onClick={() => setModalShow(true)}>
        <i className="bi bi-bullseye" /> Campaign
      </a>

      <Modal show={modalShow} onHide={handleClose}>
        <Form onSubmit={submitCampaign} id={"createCampaignForm"}>
          <Modal.Header>
            <h2 className="modal-title fs-5" id="startCampaignModalLabel">
              Create New Campaign&nbsp;
              <i className="bi bi-megaphone-fill" />
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 campaignOpts">
                <div className="row align-items-center">
                  <div className="col-lg-12 mb-3">
                    <div className="row row-cols-lg-auto g-3 mb-3 align-items-center">
                      <div className="col-12">I want</div>
                      <div className="col-12">
                        <select name="group_list" className="form-select">
                          <option
                            value=""
                            data-test={"checkthisq22312"}
                            onClick={e => {
                              setSelectedGroupName("")
                            }}
                          >
                            == SELECT GROUP ==
                          </option>
                          {groups.length > 0 &&
                            groups.map((group, gid) => (
                              <option
                                value={group.id}
                                key={`group_index_${gid}`}
                                data-test={"checkthis"}
                                onClick={() => {
                                  setSelectedGroupName(group.name)
                                  prepareCampaignName(group.name)
                                }}
                              >
                                {group.name}
                              </option>
                            ))}
                        </select>
                        {groupError && <span className="error">{groupError}</span>}
                      </div>
                      <div className="col-12">
                        <Link
                          to={"/contacts/groups?addGroup=addGroup"}
                          className="btnAddNewGreenDashed"
                          data-bs-toggle="tooltip"
                          title="Add Audience / Group / Tribe"
                        >
                          <i className="bi bi-plus-lg" />
                        </Link>
                      </div>
                    </div>
                    <div className="row row-cols-lg-auto g-3 align-items-center">
                      <div className="col-12">To</div>
                      <div className="col-12">
                        <select
                          name="campaign_opt_2"
                          id="campaign_opt_2"
                          className="form-select"
                          onChange={e => {
                            setOptDropDown(e.target.value)
                            // alert(e.target.value === CAMPAIGN_OPT_CLICK)
                            if (e.target.value === CAMPAIGN_OPT_CLICK) {
                              prepareCampaignName(null, "", e.target.value)
                            }
                          }}
                        >
                          <option value="" selected="selected">
                            == Select ==
                          </option>
                          {CAMPAIGN_OPT_OPTIONS.map((option, OptInd) => (
                            <option value={option.type} key={`campaign_opt_${OptInd}`}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {optDropDownError && <span className="error">{optDropDownError}</span>}
                      </div>
                    </div>
                  </div>

                  {optDropDown === CAMPAIGN_OPT_CLICK && (
                    <div
                      className={
                        optDropDown === "ClickThrough"
                          ? "col-lg-12 mb-3 campaignClickThroughOpt"
                          : "col-lg-12 mb-3 campaignClickThroughOpt display-none"
                      }
                    >
                      <input
                        type="url"
                        className="form-control"
                        placeholder="https://exampletargeturlhere.com"
                        value={clickThrough}
                        defaultValue={clickThrough}
                        onChange={e => setClickThrough(e.target.value)}
                      />
                      {clickUrlError && <span className="error">{clickUrlError}</span>}
                    </div>
                  )}

                  <div
                    className={
                      [
                        CAMPAIGN_OPT_DOC,
                        CAMPAIGN_OPT_PAYMENT,
                        CAMPAIGN_OPT_SCHEDULER,
                        CAMPAIGN_OPT_CHAT_BOT,
                      ].includes(optDropDown)
                        ? "col-lg-12 mb-3 campaignSpecificOpt"
                        : "col-lg-12 mb-3 campaignSpecificOpt display-none"
                    }
                  >
                    <div className="row row-cols-lg-auto g-3 align-items-center">
                      <div className="col-12">Specifically...</div>
                      <div className="col-12">
                        {optDropDown === CAMPAIGN_OPT_DOC && (
                          <div className={"campaignSpecificOptions campaignSpecificDoc"}>
                            <div className="row row-cols-lg-auto g-3 align-items-center">
                              <div className="col-12">
                                <select
                                  name="campaignSpecificDocOpts"
                                  id="campaignSpecificDocOpts"
                                  className="form-select"
                                  // onChange={(e) => {
                                  //   let index = e.nativeEvent.target.selectedIndex;
                                  //   let label = e.nativeEvent.target[index].text;
                                  //   alert(label);
                                  // }}
                                >
                                  <option
                                    value=""
                                    onClick={e => {
                                      updateSpecificData("", "")
                                    }}
                                  >
                                    == SELECT DOC ==
                                  </option>

                                  {docs.length > 0 &&
                                    docs.map((doc, did) => (
                                      <option
                                        value={doc.id}
                                        key={`doc_index_${did}`}
                                        onClick={e => {
                                          updateSpecificData(doc.id, doc.docName)
                                          prepareCampaignName(null, doc.docName)
                                        }}
                                      >
                                        {doc.docName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-12">
                                <Link
                                  to={"/drive?addDoc=addDoc"}
                                  className="btnAddNewGreenDashed"
                                  data-bs-toggle="tooltip"
                                  title="Add Doc"
                                >
                                  <i className="bi bi-plus-lg" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        {optDropDown === CAMPAIGN_OPT_PAYMENT && (
                          <div className="campaignSpecificOptions campaignSpecificProduct">
                            <div className="row row-cols-lg-auto g-3 align-items-center">
                              <div className="col-12">
                                <select
                                  name="campaignSpecificProductOpts"
                                  id="campaignSpecificProductOpts"
                                  className="form-select"
                                  onChange={e => updateSpecificData("", "")}
                                >
                                  <option value="">== SELECT PRODUCT ==</option>
                                  <option value="1">Product 1</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <a
                                  href="drive.html?action=addnew_sku"
                                  className="btnAddNewGreenDashed"
                                  data-bs-toggle="tooltip"
                                  title="Add Product"
                                >
                                  <i className="bi bi-plus-lg" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {optDropDown === CAMPAIGN_OPT_SCHEDULER && (
                          <div className={"campaignSpecificOptions campaignSpecificService"}>
                            <div className="row row-cols-lg-auto g-3 align-items-center">
                              <div className="col-12">
                                <select
                                  name="campaignSpecificServiceOpts"
                                  id="campaignSpecificServiceOpts"
                                  className="form-select"
                                  onChange={e => updateSpecificData("", "")}
                                >
                                  <option value="">== SELECT SERVICE ==</option>
                                  <option value="1">Service 1</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <a
                                  href="drive.html"
                                  className="btnAddNewGreenDashed"
                                  data-bs-toggle="tooltip"
                                  title="Add Service"
                                >
                                  <i className="bi bi-plus-lg" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {optDropDown === CAMPAIGN_OPT_CHAT_BOT && (
                          <div className={"campaignSpecificOptions campaignSpecificChatbot"}>
                            <div className="row row-cols-lg-auto g-3 align-items-center">
                              <div className="col-12">
                                <select
                                  name="campaignSpecificChatbotOpts"
                                  id="campaignSpecificChatbotOpts"
                                  className="form-select"
                                  onChange={e => updateSpecificData("", "")}
                                >
                                  <option value="">== SELECT CHATBOT ==</option>
                                  <option value="Chatbot">Which Chatbot?</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <a
                                  href="drive.html"
                                  className="btnAddNewGreenDashed"
                                  data-bs-toggle="tooltip"
                                  title="Add Chatbot"
                                >
                                  <i className="bi bi-plus-lg" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {((optDropDown === CAMPAIGN_OPT_DOC && specificDoc !== "") ||
                    (optDropDown === CAMPAIGN_OPT_PAYMENT && specificProduct !== "") ||
                    (optDropDown === CAMPAIGN_OPT_SCHEDULER && specificScheduler !== "") ||
                    (optDropDown === CAMPAIGN_OPT_CHAT_BOT && specificBot !== "") ||
                    optDropDown === CAMPAIGN_OPT_CLICK) && (
                    <div className="col-lg-12 mb-3 campaignSpecificChoosenOpt">
                      <div className="row row-cols-lg-auto g-3 align-items-center">
                        <div className="col-12">& I'll request it via</div>
                        <div className="col-12">
                          <select
                            name="campaignSpecificOnceChoosen"
                            id="campaignSpecificOnceChoosen"
                            className="form-select"
                            onChange={e => {
                              setReqOptType(e.target.value)
                            }}
                          >
                            <option value="">== METHOD / CHANNEL ==</option>
                            {REQUEST_OPTIONS.map((reqOpt, reqOptInx) => {
                              return (
                                <option value={reqOpt.type} disabled={reqOpt.type === ""}>
                                  {reqOpt.label}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        {reqOptType === "Sequence" && (
                          <>
                            <div className="col-12">
                              <select
                                name="sequence_list_for_start_campaign"
                                id="sequence_list_for_start_campaign"
                                className="form-select"
                              >
                                <option value="">== Select Sequence ==</option>
                                <option>Actual seq. list goes here...</option>
                              </select>
                            </div>

                            <div className="col-12 addNewSeqBox">
                              <a
                                href="drive.html?action=addnew_sequence"
                                className="btnAddNewGreenDashed"
                                data-bs-toggle="tooltip"
                                title="Add Sequence"
                              >
                                <i className="bi bi-plus-lg" />
                              </a>
                            </div>

                            <div className="col-lg-12 remove_sequence_list_for_start_campaign">
                              <input
                                type="checkbox"
                                name="remove_from_sequence_list"
                                id="remove_from_sequence_list"
                                className="css-checkbox"
                                value="1"
                                checked={removeReceipt ? "checked" : ""}
                                onClick={() => setRemoveReceipt(!removeReceipt)}
                              />
                              <label htmlFor="remove_from_sequence_list" className="css-checkbox-label">
                                Remove each recipient from sequence when goal is reached
                              </label>
                            </div>

                            <div className="col-lg-12 mb-3 remove_sequence_list_for_start_campaign">
                              <input
                                type="checkbox"
                                name="pause_sequence"
                                id="pause_sequence"
                                className="css-checkbox"
                                value="1"
                                checked={pauseSequence ? "checked" : ""}
                                onClick={() => setPauseSequence(!pauseSequence)}
                              />
                              <label htmlFor="pause_sequence" className="css-checkbox-label">
                                Pause sequence for that recipient if they respond via email or SMS
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {specificDataError && <div className="error">{specificDataError}</div>}
                  {requestViaError && <div className="error">{requestViaError}</div>}
                  {requestViaSeqError && <div className="error">{requestViaSeqError}</div>}

                  {((optDropDown === CAMPAIGN_OPT_DOC && specificDoc !== "") ||
                    (optDropDown === CAMPAIGN_OPT_PAYMENT && specificProduct !== "") ||
                    (optDropDown === CAMPAIGN_OPT_SCHEDULER && specificScheduler !== "") ||
                    (optDropDown === CAMPAIGN_OPT_CHAT_BOT && specificBot !== "") ||
                    optDropDown === CAMPAIGN_OPT_CLICK) && (
                    <div className="col-lg-12 createWorkroomOpt" id="createWorkroomOpt">
                      <div className="input-group">
                        <div className="input-group-text">We'll create a Campaign for this, called</div>
                        <input
                          type="text"
                          className="form-control"
                          id="campaignSpecificName"
                          value={campaignName}
                          defaultValue={campaignName}
                          placeholder="Campaign Unique Title / Label"
                          onChange={e => setCampaignName(e.target.value)}
                        />
                      </div>
                      {campaignNameError && <div className="error">{campaignNameError}</div>}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {formError && <div className="error">{formError}</div>}
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-default" data-bs-dismiss="modal">
              <i className="bi bi-x-lg" /> Close
            </button>
            <Button className="btn btn-primary btnStartCampaign" type={"submit"}>
              <i className="bi bi-check2" />
              Save & Start Campaign
              <i className="bi bi-megaphone-fill" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default NewCampaignPopup
