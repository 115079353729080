import * as React from "react"
import TableRow from "./tableRow"
import { useRef } from "react"
import clsx from "clsx"
import CampaignLazyLoad from "./campaignLazyLoad"
import AxiosInstance from "../../../services/axiosInstance"

const CampaignTable = () => {
  const listApi = "/campaign/list"
  const triggerRef = useRef(null)
  const onGrabData = (currentPage, pages_count) => {
    return new Promise(async resolve => {
      let data = {
        items: [],
        pages_count: pages_count,
      }

      if (pages_count === null || currentPage <= pages_count) {
        data = await AxiosInstance.get(`${listApi}`, {
          params: {
            page: currentPage,
          },
        }).then(response => {
          return response.data
        })
      }
      resolve(data, pages_count)
    })
  }

  const { data, loading } = CampaignLazyLoad({ triggerRef, onGrabData })

  return (
    <React.Fragment>
      <table id="tblCampaignList" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th width="4%" />
            <th width="56%">Campaign / Experiment (Audience)</th>
            <th width="9%">Traffic</th>
            <th width="9%">
              Goals{" "}
              <span
                data-bs-toggle="tooltip"
                title="Clicks, Captures or Sales - whatever your success metric is for that campaign"
              >
                <i className="bi bi-question-circle-fill" />
              </span>
            </th>
            <th width="9%" className="d-none d-md-table-cell">
              ROI
            </th>
            <th width="8%" className="d-none d-md-table-cell">
              Last Activity
            </th>
            <th width="5%" className="text-end d-none d-lg-table-cell" />
          </tr>
        </thead>
        <tbody>
          {data.map((campaign, index) => {
            return <TableRow campaign={campaign} key={`contact_row_${index}`} />
          })}
        </tbody>
      </table>
      <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
    </React.Fragment>
  )
}

export default CampaignTable
