import * as React from "react"
import { Link } from "gatsby"
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import _uniqueId from "lodash/uniqueId"
import TagListView from "../../Global/Tags/tagListView"
import { timeFormatter } from "../../../services/timeHelper"
import TimeAgo from "timeago-react"

const TableRow = ({ campaign }) => {
  const trafficPopOver = (
    <Popover title={"test"}>
      <ul className="campaignSummaryPopover">
        <li className="CP_text_not_started">
          <span>3545</span>
          <i className="bi bi-hourglass-top" /> Not started
        </li>
        <li className="text-warning">
          <span>56</span>
          <i className="bi bi-clock-fill" /> Started
        </li>
        <li className="text-success">
          <span>90</span>
          <i className="bi bi-check2" /> Completed
        </li>
        <li className="text-yellow">
          <span>0</span>
          <i className="bi bi-code-slash" /> Missing merge-tags
        </li>
        <li className="text-danger">
          <span>0</span>
          <i className="bi bi-x-circle" /> Invalid emails
        </li>
        <li className="CP_text_soft_bounce">
          <span>0</span>
          <i className="bi bi-person-fill-exclamation" /> Soft bounce
        </li>
        <li className="text-danger">
          <strong>
            <span>0</span>
            <i className="bi bi-exclamation-triangle-fill" /> Hard bounce
          </strong>
        </li>
        <li className="text-dark">
          <span>0</span>
          <i className="bi bi-person-dash-fill" /> Unsubscribed
        </li>
        <li className="text-black-50">
          <span>0</span>
          <i className="bi bi-person-x-fill" /> Do not contact
        </li>
      </ul>
    </Popover>
  )

  const repliesPopOver = (
    <Popover title="Popover bottom">
      <ul className="campaignSummaryPopover">
        <li className="CP_text_not_started">
          <span>3545</span>
          <i className="bi bi-hourglass-top" /> Not started
        </li>
        <li className="text-warning">
          <span>56</span>
          <i className="bi bi-clock-fill" /> Started
        </li>
        <li className="text-success">
          <span>90</span>
          <i className="bi bi-check2" /> Completed
        </li>
        <li className="text-yellow">
          <span>0</span>
          <i className="bi bi-code-slash" /> Missing merge-tags
        </li>
        <li className="text-danger">
          <span>0</span>
          <i className="bi bi-x-circle" /> Invalid emails
        </li>
        <li className="CP_text_soft_bounce">
          <span>0</span>
          <i className="bi bi-person-fill-exclamation" /> Soft bounce
        </li>
        <li className="text-danger">
          <strong>
            <span>0</span>
            <i className="bi bi-exclamation-triangle-fill" /> Hard bounce
          </strong>
        </li>
        <li className="text-dark">
          <span>0</span>
          <i className="bi bi-person-dash-fill" /> Unsubscribed
        </li>
        <li className="text-black-50">
          <span>0</span>
          <i className="bi bi-person-x-fill" /> Do not contact
        </li>
      </ul>
    </Popover>
  )

  const roiPopOver = (
    <Tooltip>
      <span>(CPC $1.22 EPC $1.00)</span>
    </Tooltip>
  )

  return (
    <tr>
      <td className="text-center tableColChkDel">
        <div className="tableColChkDelBox">
          <input type="checkbox" name="fileid[]" id="C_ID1" className="css-checkbox" />
          <label htmlFor="C_ID1" className="css-checkbox-label" />
          <span className="tableColChkSpan">
            <img src="images/pedro.jpg" />
          </span>
        </div>
      </td>
      <td className="campaign_name">
        <div className="d-inline-block" style={{ width: "100%" }}>
          <Link to={`detail/${campaign.id}`} className="activeCampaign">
            {campaign.name}
          </Link>
          <Link to={`/contacts/groups/detail/${campaign.contactGroup.id}`} className="ms-2">
            <i className="bi bi-people-fill" /> {campaign.contactGroup.name}&nbsp;
          </Link>
          via
          <i className="bi bi-three-dots" /> ChiroFunding Sequence
          {campaign.tags.length > 0 && <TagListView tags={campaign.tags} />}
          <div className="progress mt-2 campaignProgress">
            <div
              className="progress-bar CP_not_started"
              role="progressbar"
              aria-label="Not started"
              style={{ width: "50%" }}
              aria-valuenow="3545"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar bg-warning CP_started"
              role="progressbar"
              aria-label="Started"
              style={{ width: "30%" }}
              aria-valuenow="56"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar bg-success CP_completed"
              role="progressbar"
              aria-label="Completed"
              style={{ width: "20%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar CP_missing_merge_tags"
              role="progressbar"
              aria-label="Missing merge-tags"
              style={{ width: "0%" }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar bg-danger CP_invalid_emails"
              role="progressbar"
              aria-label="Invalid emails"
              style={{ width: "0%" }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar CP_unsubscribed"
              role="progressbar"
              aria-label="Unsubscribed"
              style={{ width: "0%" }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            />
            <div
              className="progress-bar CP_do_not_contact"
              role="progressbar"
              aria-label="Do not contact"
              style={{ width: "0%" }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </td>
      <td>
        <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={trafficPopOver}>
          <sapn>116 sent</sapn>
        </OverlayTrigger>{" "}
        (80%)
      </td>
      <td>
        <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={repliesPopOver}>
          <sapn>3 replies</sapn>
        </OverlayTrigger>{" "}
        (3%)
      </td>
      <td className="d-md-table-cell failureAgreement">
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={roiPopOver}>
          <strong>-22%</strong>
        </OverlayTrigger>
      </td>
      <td className="d-none d-md-table-cell">
        <TimeAgo datetime={timeFormatter(campaign.updatedAt)} />
      </td>
      <td className="text-end tableColAction d-none d-lg-table-cell">
        <a className="btn btn-delete" data-bs-toggle="tooltip" title="Delete">
          <i className="bi bi-trash-fill" />
        </a>
      </td>
    </tr>
  )
}

export default TableRow
